<template>
  <div>
    <redoc />
  </div>
</template>

<script>
import { load, loaded } from './api-viewer-loader';
import apiCall from '@/adonis-api/api-call';

export default {
  beforeCreate() {
    load(); // load latest redoc library
  },

  mounted() {
    loaded.then(async () => {
      const result = await apiCall('GET', '/api-doc-url');
      window.Redoc.init(result.url, {
        hideHostname: true,
        requiredPropsFirst: true,
        scrollYOffset: 50,
        noAutoAuth: true,
      });
    });
  },
};
</script>

<style lang="css">
  .api-content .api-info > p {
    display: none !important;
  }
  .api-content .api-info > div > div > span {
    display: none !important;
  }
</style>
