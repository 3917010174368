export const loaded = new Promise(resolve => {
  // eslint-disable-line no-unused-vars
  if (typeof window === 'undefined') {
    // Do nothing if run from server-side
    return;
  }
  window.vueRedocInit = resolve;
});

/**
 * @param version   Specifies which version to load, otherwise
 *                  the default 'latest' will be loaded.
 */
export const load = () => {
  if (typeof document === 'undefined') {
    // Do nothing if run from server-side
    return;
  }
  if (window.Redoc) {
    // Library is already loaded, no need to load it again!
    return;
  }
  const redocScript = document.createElement('SCRIPT');

  // TODO: This will need to be updated when a full release is available:
  const url = 'https://cdn.jsdelivr.net/npm/redoc@2.0.0-rc.31/bundles/redoc.standalone.min.js';
  // 'https://cdn.jsdelivr.net/npm/redoc@next/bundles/redoc.standalone.js';

  redocScript.setAttribute('src', url);
  redocScript.setAttribute('async', '');
  redocScript.setAttribute('defer', '');
  document.body.appendChild(redocScript);

  redocScript.onload = window.vueRedocInit;
};
